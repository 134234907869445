import React from 'react'
import { connect } from 'react-redux'
import { ApolloProvider } from '@apollo/react-hooks'
import { apolloClient } from 'lib/utils/apolloClient'

let apolloClients = {
  baseClient: null,
  labFulfillmentClient: null,
  baseClientWithQueuedMutations: null,
  serviceInventoryClient: null,
  eventTimelineClient: null
}

const getApolloClient = () => {
  return apolloClients
}

const setupClients = idToken => {
  apolloClients.baseClient = apolloClient({
    apiUrl: `${global.config.SAMPLE_MANAGEMENT_SERVICE_ENDPOINT}/api`,
    idToken,
  })
  apolloClients.labFulfillmentClient = apolloClient({
    apiUrl: `${global.config.LAB_FULFILLMENT_SERVICE_ENDPOINT}/api`,
    idToken,
  })
  apolloClients.eventTimelineClient = apolloClient({
    apiUrl: `${global.config.EVENT_TIMELINE_SERVICE_ENDPOINT}/api/events`,
    idToken
  })
  apolloClients.baseClientWithQueuedMutations = apolloClient({
    apiUrl: `${global.config.SAMPLE_MANAGEMENT_SERVICE_ENDPOINT}/api`,
    idToken,
    shouldQueueMutations: true,
  })
  apolloClients.serviceInventoryClient = apolloClient({
    apiUrl: `${global.config.SERVICE_INVENTORY_SERVICE_ENDPOINT}/api`,
    idToken,
  })

  return apolloClients
}

const ApolloClientWrapper = ({ children, idToken }) => {
  let { baseClient } = setupClients(idToken)

  return baseClient ? (
    <ApolloProvider client={baseClient}>{children}</ApolloProvider>
  ) : null
}

export { ApolloClientWrapper, getApolloClient }
export default connect(state => {
  return {
    idToken: state.idToken.data,
  }
})(ApolloClientWrapper)
