import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { useLazyQuery } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'
import { getApolloClient } from 'components/ApolloClientWrapper'

import { renderError } from '@licnz/react-toast-notifications'
import { SEARCH_ONE } from './queries'

import {
  SearchBar,
  SearchBarResults,
  generateOptionsFromSearchResult,
  MostRecentDropdown,
} from '@licnz/react-diagnostics-search'

const SearchOne = ({ onClose, q }) => {
  const [noResultsMessage, setNoResultsMessage] = useState(null)
  const [searchResult, setSearchResult] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const { labFulfillmentClient } = getApolloClient()

  const [
    searchOneQuery,
    { data: data, error: searchOneError, loading: searchOneLoading },
  ] = useLazyQuery(SEARCH_ONE, { client: labFulfillmentClient, fetchPolicy: 'no-cache' })

  useEffect(() => {
    setSearchQuery(q)
  }, [q])

  useEffect(() => {
    if (searchOneError) renderError({ message: searchOneError.message })
  }, [searchOneError])

  useEffect(() => {
    if (data) {
      if (data.searchOne) {
        setSearchResult(data.searchOne)
        saveSearchString()
      } else {
        setNoResultsMessage(`No results found for '${searchQuery}'`)
      }
    }
  }, [data])

  function handleSearchChange(q) {
    if (q === '') handleReset()
    else setSearchQuery(q)
  }

  function handleReset() {
    setSearchQuery('')
    clearSearchState()
  }

  function handleSubmit() {
    clearSearchState()
    searchOneQuery({ variables: { text: searchQuery.toUpperCase() } })
  }

  function onResultSelect(option, selectedIndex) {
    localStorage.setItem(lastSelectedKeyName(option._type), selectedIndex)
    window.location = option.url
    onClose()
  }

  function handleMostRecentSelect(value) {
    if (value) {
      setSearchQuery(value)
      searchOneQuery({ variables: { text: value.toUpperCase() } })
    }
  }

  function clearSearchState() {
    setSearchResult(null)
    setNoResultsMessage(null)
  }

  function clearSearchResults() {
    clearSearchState()
  }

  let mostRecentSearches = (localStorage.getItem('mostRecentSearches') || '')
    .split(',')
    .filter(Boolean)

  function saveSearchString() {
    mostRecentSearches.push(searchQuery)
    let uniqueSearches = [...new Set(mostRecentSearches)]
    localStorage.setItem('mostRecentSearches', uniqueSearches.slice(-5).join(','))
  }

  function lastSelectedKeyName(resultType) {
    return `${resultType}-focus`
  }

  function lastSelectedIndex(optionsType) {
    let val = parseInt(localStorage.getItem(lastSelectedKeyName(optionsType)))
    return Number.isInteger(val) ? val : null
  }

  let { options, _type: optionsType } = generateOptionsFromSearchResult(searchResult)
  let selectedOptionIndex = lastSelectedIndex(optionsType)

  return (
    <>
      <SearchBar
        loading={searchOneLoading}
        onChange={handleSearchChange}
        onReset={handleReset}
        onSubmit={handleSubmit}
        onFocus={clearSearchResults}
        value={searchQuery}
      />

      {searchResult || noResultsMessage ? (
        <SearchBarResults
          onClose={onClose}
          options={options}
          optionsType={optionsType}
          noResultsMessage={noResultsMessage}
          searchQuery={searchQuery}
          onSelect={onResultSelect}
          initialSelectedIndex={selectedOptionIndex}
        />
      ) : (
        <MostRecentDropdown
          options={mostRecentSearches}
          onSelect={handleMostRecentSelect}
          identifier='searchBar'
        />
      )}
    </>
  )
}

export { SearchOne }
export default compose(withRouter)(SearchOne)
