import { combineReducers } from 'redux'
import dataReducer from 'lib/reducers/dataReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import addresses from './addressesReducer'
import agriManager from './agriManagerReducer'
import billingEntities from './billingEntitiesReducer'
import contactNumbers from './contactNumbersReducer'
import creditValidity from './creditValidityReducer'
import organisation from './organisationReducer'
import spsMemberships from './spsMembershipsReducer'
import { customerTypes } from 'lib/components/ReduxCustomer/types'

const initialState = null
const baseType = customerTypes.FETCH_OPERATION

const reducer = combineReducers({
  data: dataReducer({ initialState, baseType }),
  requestState: requestStateReducer(baseType),
  addresses,
  agriManager,
  billingEntities,
  contactNumbers,
  creditValidity,
  organisation,
  spsMemberships,
})

export default reducer
