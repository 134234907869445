import MutationQueueLink from '@adobe/apollo-link-mutation-queue'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { createLink as link } from 'apollo-absinthe-upload-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'

function customLink({ apiUrl, idToken, shouldQueueMutations }) {
  let links = [
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    link({
      uri: apiUrl,
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    }),
  ]

  if (shouldQueueMutations) {
    links.unshift(new MutationQueueLink())
  }

  return ApolloLink.from(links)
}

function client({ apiUrl, idToken, shouldQueueMutations }) {
  return new ApolloClient({
    link: customLink({ apiUrl, idToken, shouldQueueMutations }),
    cache: new InMemoryCache(),
  })
}

export default client
