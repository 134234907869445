import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles'

const SectionHeading = ({ children, title }) => {
  const wrapperClassNames = `${styles.sectionHeadingWrapper} ${title.length === 0 &&
    styles.emptyTitle}`

  return (
    <div className={wrapperClassNames}>
      <h4 className={styles.sectionHeading}>{title}</h4>
      {children ? <div className={styles.childrenWrapper}>{children}</div> : null}
    </div>
  )
}

SectionHeading.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string.isRequired,
}

export default SectionHeading
