import { get, post } from 'lib/reduxHelpers/thunks'
import { href } from '@licnz/js-utils'
import { proxyUrl } from 'lib/utils/request'
import { customerTypes } from '../types'
import {
  AGRI_MANAGER,
  SPS_MEMBER,
  PVWH_SPS_DISCOUNT_MEMBER,
} from 'constants/relationshipTypes'

function fetchCustomer(operationLink) {
  return function(dispatch) {
    dispatch(fetchOperation(operationLink)).then(({ data }) => {
      dispatch(fetchOperationAddresses(data.party))
      dispatch(fetchOrganisation(data.party)).then(response => {
        dispatch(fetchOrganisationContactNumbers(response.data.party))
      })
      dispatch(fetchAgriManager(operationLink))
      dispatch(fetchBillingEntities(data.party)).then(response => {
        dispatch(fetchOperationCreditValidity(response.data.items))
      })
      dispatch(fetchSPSMemberships(operationLink))
    })
  }
}

function fetchOperation(operationLink) {
  let baseType = customerTypes.FETCH_OPERATION
  let url = proxyUrl({ link: operationLink })

  return get({ baseType, url, extractPayload: response => response.data.party })
}

function fetchOperationAddresses(operation) {
  let operationAddressesLink = href({
    links: operation.links,
    rel: 'addresses',
  })
  let url = proxyUrl({ link: operationAddressesLink })
  let baseType = customerTypes.FETCH_OPERATION_ADDRESSES

  return get({
    url,
    baseType,
    extractPayload: response => response.data.addresses,
  })
}

function fetchOrganisationContactNumbers(organisation) {
  let organisationContactNumbers = href({
    links: organisation.links,
    rel: 'contact_numbers',
  })
  let url = proxyUrl({ link: organisationContactNumbers })
  let baseType = customerTypes.FETCH_ORGANISATION_CONTACT_NUMBERS

  return get({
    url,
    baseType,
    extractPayload: response => response.data.contact_numbers,
  })
}

function fetchOrganisation(operation) {
  let organisationPartyLink = href({
    links: operation.links,
    rel: 'up',
  })

  let url = proxyUrl({ link: organisationPartyLink })
  let baseType = customerTypes.FETCH_ORGANISATION

  return get({
    url,
    baseType,
    extractPayload: response => response.data.party,
  })
}

function fetchBillingEntities(operation) {
  let operationPartyLink = href({
    links: operation.links,
    rel: 'up',
  })

  let url = proxyUrl({
    link: `${global.config.ACCOUNT_SERVICE_ENDPOINT}/billing_entities/search`,
  })
  let baseType = customerTypes.FETCH_BILLING_ENTITIES
  let body = { directives: '_asc', party_identity: operationPartyLink }

  return post({
    baseType,
    url,
    body,
    extractPayload: response => response.data.items,
  })
}

function fetchOperationCreditValidity(billingEntities) {
  let billingEntityId = billingEntities.length ? billingEntities[0].id : null

  let baseType = customerTypes.FETCH_CREDIT_VALIDITY
  let url = proxyUrl({
    link: `${global.config.ACCOUNT_SERVICE_ENDPOINT}/billing_entities/${billingEntityId}/credit_validity`,
  })

  let body = {
    _type: 'credit_validity',
    payment_method_symbol: 'http://purl.org/goodrelations/v1#ByInvoice',
  }

  return post({
    baseType,
    url,
    body,
  })
}

const extractAgriManager = partyResponse => {
  return partyResponse?.data?.relationships?.graph?.nodes?.find(node => {
    return node.kind === 'person'
  })
}

function fetchAgriManager(operationLink) {
  let baseType = customerTypes.FETCH_AGRI_MANAGER
  let url = proxyUrl({
    link: `${global.config.RELATIONSHIP_SERVICE_ENDPOINT}/api/v1/relationships/search`,
  })

  let body = {
    to: { query: { match: { id: operationLink } } },
    rel: { rel: [AGRI_MANAGER] },
  }

  return post({
    url,
    baseType,
    body,
    extractPayload: extractAgriManager,
  })
}

const extractSPSMemberships = searchResponse => {
  return searchResponse?.data?.relationships?.graph?.edges?.map(edge => {
    return edge.relation
  })
}

function fetchSPSMemberships(operationLink) {
  let baseType = customerTypes.FETCH_SPS_MEMBERSHIPS
  let url = proxyUrl({
    link: `${global.config.RELATIONSHIP_SERVICE_ENDPOINT}/api/v1/relationships/search`,
  })

  let body = {
    from: { query: { match: { id: operationLink } } },
    rel: { rel: [SPS_MEMBER, PVWH_SPS_DISCOUNT_MEMBER] },
  }

  return post({
    url,
    baseType,
    body,
    extractPayload: extractSPSMemberships,
  })
}

function clearCustomer() {
  return function(dispatch) {
    dispatch({ type: customerTypes.CLEAR_FETCH_OPERATION_ADDRESSES })
    dispatch({ type: customerTypes.CLEAR_FETCH_ORGANISATION_CONTACT_NUMBERS })
    dispatch({ type: customerTypes.CLEAR_FETCH_BILLING_ENTITIES })
    dispatch({ type: customerTypes.CLEAR_FETCH_CREDIT_VALIDITY })
    dispatch({ type: customerTypes.CLEAR_FETCH_ORGANISATION })
    dispatch({ type: customerTypes.CLEAR_FETCH_OPERATION })
    dispatch({ type: customerTypes.CLEAR_FETCH_AGRI_MANAGER })
    dispatch({ type: customerTypes.CLEAR_FETCH_SPS_MEMBERSHIPS })
  }
}

export {
  clearCustomer,
  fetchAgriManager,
  fetchBillingEntities,
  fetchCustomer,
  fetchOperation,
  fetchOperationAddresses,
  fetchOperationCreditValidity,
  fetchOrganisation,
  fetchOrganisationContactNumbers,
  fetchSPSMemberships,
}
