import { createSelector } from 'reselect'

const getCustomer = createSelector([data => data], data => {
  return data ? data.customer.operation.data : null
})

const getAddresses = createSelector([data => data], data => {
  return data ? data.customer.operation.addresses.data : null
})

const getCreditValidity = createSelector([data => data], data => {
  return data ? data.customer.operation.creditValidity.data.credit_validity : null
})

const getContactNumbers = createSelector([data => data], data => {
  return data ? data.customer.operation.contactNumbers.data : null
})

const getAgriManager = createSelector([data => data], data => {
  return data ? data.customer.operation.agriManager.data : null
})

const getOrganisation = createSelector([data => data], data => {
  return data ? data.customer.operation.organisation.data : null
})

const getSPSMemberships = createSelector([data => data], data => {
  return data ? data.customer.operation.spsMemberships.data : null
})

export {
  getAddresses,
  getAgriManager,
  getContactNumbers,
  getCreditValidity,
  getCustomer,
  getOrganisation,
  getSPSMemberships,
}
