const AHB_NUMBER_REGEX = /^\d{7}-\d{2}-\d+/ // e.g. 1234567-12-1
const BIRTH_ID_REGEX = /^[a-zA-Z]{2,5}-\d{2}-[1-9]\d{0,4}$/ // e.g. PTPTP-12-12345
const SUFFIXED_BIRTH_ID_REGEX = /^[a-zA-Z]{2,5}-\d{2}-[1-9]\d{0,4}-[a-zA-Z]{1}$/ // e.g. PTPTP-12-12345-A
const BULL_CODE_REGEX = /^[1-9]\d{5,}$/ // 6+ digits, no leading zeroes
const MANAGEMENT_NUMBER_REGEX = /^[1-9]\d{0,4}$/ // 1-5 digits, no leading zeroes
const EID_REGEX = /^\d{3} \d+$/ // 3 digits, space, some digits

const BIRTH_ID_PARTICIPANT_CODE_AND_YEAR_REGEX = /^[a-zA-Z]{2,5}-\d{2}$/

const isAHBNumber = id => !!(id && id.match(AHB_NUMBER_REGEX))

const isBirthId = id => !!(id && id.match(BIRTH_ID_REGEX))

const isSuffixedBirthId = id => !!(id && id.match(SUFFIXED_BIRTH_ID_REGEX))

const isBullCode = id => !!(id && id.match(BULL_CODE_REGEX))

const isManagementNumber = id => !!(id && id.match(MANAGEMENT_NUMBER_REGEX))

const trimmedBirthId = rawAnimalId => {
  return rawAnimalId
    .replace(/_/g, '')
    .replace(/ /g, '')
    .toUpperCase()
}

const isBirthIdWithoutNumber = id =>
  id && id.match(BIRTH_ID_PARTICIPANT_CODE_AND_YEAR_REGEX)

const isEid = id => !!(id && id.match(EID_REGEX))

export {
  isAHBNumber,
  isBirthId,
  isSuffixedBirthId,
  isBullCode,
  isManagementNumber,
  trimmedBirthId,
  isBirthIdWithoutNumber,
  isEid,
}
