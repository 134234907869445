const A2_BILLING_PARTNER = 'urn:lic:graph:rel:a2_billing_partner'
const A2_CONTRACT = 'urn:lic:graph:rel:a2_contract'
const AGRI_MANAGER = 'urn:lic:graph:rel:agri_manager'
const FARMING_OPERATION = 'urn:lic:graph:rel:farming_operation'
const FARM_MANAGER = 'urn:lic:graph:rel:farm_management'
const OWNERSHIP = 'urn:lic:graph:rel:ownership'
const PRIMARY_CONTACT = 'urn:lic:graph:rel:primary_contact'
const SECONDARY_CONTACT = 'urn:lic:graph:rel:secondary_contact'
const TRUSTEE = 'urn:lic:graph:rel:trustee'
const WEBSHOP_USER = 'urn:lic:graph:rel:webshop_user'
const SPS_MEMBER = 'urn:lic:graph:rel:sps_member'
const PVWH_SPS_DISCOUNT_MEMBER = 'urn:lic:graph:rel:pvwh_sps_discount_member'

const RELATIONSHIP_TYPES = {
  A2_BILLING_PARTNER: [A2_BILLING_PARTNER],
  A2_CONTRACT: [A2_CONTRACT],
  AGRI_MANAGER: [AGRI_MANAGER],
  FARMING_OPERATION: [FARMING_OPERATION],
  FARM_MANAGER: [FARM_MANAGER],
  OWNERSHIP: [OWNERSHIP],
  PRIMARY_CONTACT: [PRIMARY_CONTACT],
  SECONDARY_CONTACT: [SECONDARY_CONTACT],
  TRUSTEE: [TRUSTEE],
  WEBSHOP_USER: [WEBSHOP_USER],
  SPS_MEMBER: [SPS_MEMBER],
  PVWH_SPS_DISCOUNT_MEMBER: [PVWH_SPS_DISCOUNT_MEMBER],
}

const RELATIONSHIP_TYPE_DICTIONARY = {
  [A2_BILLING_PARTNER]: 'A2 Billing Partner',
  [A2_CONTRACT]: 'A2 Contract',
  [AGRI_MANAGER]: 'Agri Manager',
  [FARMING_OPERATION]: 'Farming Operation',
  [FARM_MANAGER]: 'Farm Manager',
  [OWNERSHIP]: 'Ownership',
  [PRIMARY_CONTACT]: 'Primary Contact',
  [SECONDARY_CONTACT]: 'Secondary Contact',
  [TRUSTEE]: 'Trustee',
  [WEBSHOP_USER]: 'Webshop User',
  [SPS_MEMBER]: 'SPS Member',
  [PVWH_SPS_DISCOUNT_MEMBER]: 'PVWH SPS Discount Member',
}

export {
  A2_BILLING_PARTNER,
  A2_CONTRACT,
  AGRI_MANAGER,
  FARMING_OPERATION,
  FARM_MANAGER,
  OWNERSHIP,
  PRIMARY_CONTACT,
  RELATIONSHIP_TYPE_DICTIONARY,
  SECONDARY_CONTACT,
  TRUSTEE,
  WEBSHOP_USER,
  SPS_MEMBER,
  PVWH_SPS_DISCOUNT_MEMBER,
}

export default RELATIONSHIP_TYPES
